import { TAllUsers } from '@project/view-models';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import {
  TAllCustomFields,
  TWorkspaceCustomFields,
} from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TPoint, TPointCustomFields } from 'src/app/project/modules/points/points.model';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { TFilters } from '../site-filter.model';
import {
  checkCostForKeyword,
  checkDateForKeyword,
  checkNumbersForKeyword,
  checkPercentageForKeyword,
  checkRichTextForKeyword,
  checkTextForKeyword,
  checkTimeForKeyword,
  checkTimelineForKeyword,
} from './filter-keyword-custom-fields';

export function checkPointsWithKeyword(point: TPoint, filters: TFilters): boolean {
  return filters.pointsWithKeyword && filters.pointsWithKeyword.includes(point._id);
}

export function checkCustomFieldsForKeyword(
  _point: TPoint,
  customFields: TAllCustomFields,
  filters: TFilters,
): boolean {
  const _keyword = filters.keyword;

  if (_keyword.length > 0) {
    for (let index = 0; index < filters.customFields.length; index++) {
      const filterField = filters.customFields[index];
      const pointCustomFields = _point.customFieldsMap || {};
      const pointCustomField = pointCustomFields[filterField.id];

      if (
        filterCustomFields(
          customFields[filterField.workspaceId][filterField.id].type,
          pointCustomField,
          _keyword,
        )
      ) {
        return true;
      }
    }
  }

  return false;
}

export function checkOverviewCustomFieldsForKeyword(
  _point: TPoint,
  _keyword: string,
  customFields: TAllCustomFields,
): boolean {
  if (!_keyword.length) {
    return false;
  }

  const pointCustomFields = _point.customFieldsMap || {};
  const workspacesIds = Object.keys(customFields);

  for (let index = 0; index < workspacesIds.length; index++) {
    const workspaceId = workspacesIds[index];
    const workspaceCustomFields = customFields[workspaceId];

    if (
      checkOverviewWorkspaceCustomFieldsForKeyword(
        workspaceCustomFields,
        pointCustomFields,
        _keyword,
      )
    ) {
      return true;
    }
  }

  return false;
}

export function checkAssigneesForKeyword(
  _point: TPoint,
  _keyword: string,
  allUsers: TAllUsers,
): boolean {
  const assignees = _point.assignees;

  if (_keyword.length > 0) {
    for (let index = 0; index < assignees.length; index++) {
      const assignee = assignees[index];

      if (
        allUsers[assignee] &&
        allUsers[assignee].userName.toLowerCase().indexOf(_keyword.toLowerCase()) > -1
      ) {
        return true;
      }
    }
  }

  return false;
}

function filterCustomFields(
  type: string,
  pointCustomField: TPointCustomField,
  _keyword: string,
): boolean {
  if (pointCustomField) {
    switch (type) {
      case ECustomFieldType.LIST:
      case ECustomFieldType.TEXT:
        return checkTextForKeyword(pointCustomField, _keyword);
      case ECustomFieldType.COST:
        return checkCostForKeyword(pointCustomField, _keyword);
      case ECustomFieldType.NUMBERS:
        return checkNumbersForKeyword(pointCustomField, _keyword);
      case ECustomFieldType.PERCENTAGE:
        return checkPercentageForKeyword(pointCustomField, _keyword);
      case ECustomFieldType.TIMELINE:
        return checkTimelineForKeyword(pointCustomField, _keyword);
      case ECustomFieldType.DATE:
        return checkDateForKeyword(pointCustomField, _keyword);
      case ECustomFieldType.TIME:
        return checkTimeForKeyword(pointCustomField, _keyword);
      case ECustomFieldType.RICHTEXT:
        return checkRichTextForKeyword(pointCustomField, _keyword);
      default:
        return false;
    }
  } else {
    return false;
  }
}

function checkOverviewWorkspaceCustomFieldsForKeyword(
  workspaceCustomFields: TWorkspaceCustomFields,
  pointCustomFields: TPointCustomFields,
  keyword: string,
): boolean {
  const workspaceCustomFieldIds = Object.keys(workspaceCustomFields);

  for (let fieldIndex = 0; fieldIndex < workspaceCustomFieldIds.length; fieldIndex++) {
    const workspaceCustomFieldId = workspaceCustomFieldIds[fieldIndex];
    const fieldId = workspaceCustomFields[workspaceCustomFieldId].id;

    if (checkOverviewCustomFieldForKeyword(pointCustomFields, fieldId, keyword)) {
      return true;
    }
  }

  return false;
}

function checkOverviewCustomFieldForKeyword(
  pointCustomFields: TWorkspaceCustomFields,
  fieldId: string,
  keyword: string,
): boolean {
  const pointCustomField = pointCustomFields[fieldId];
  const match = filterCustomFields(pointCustomField?.type, pointCustomField, keyword);

  if (match) {
    return true;
  } // else keep searching other fields

  return false;
}
