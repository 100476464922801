import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import { Store } from '@ngrx/store';

import { UpdatePointCustomField } from 'src/app/project/modules/points/points.actions';
import { TPoint } from 'src/app/project/modules/points/points.model';

import { ModalService } from 'src/app/project/components/modal/modal.service';
import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';
import { PromptService } from '../../../../../components/prompt/prompt.service';
import { PointActivityService } from '../../point-timeline/point-activity.service';
import { PointFieldsService } from '../point-fields.service';

import { ClickOutsideHandler } from '@core/services';
import { of, Subject, timer } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { transformSplitTimeCF } from 'src/app/project/modules/custom-fields/custom-field-time/custom-field-time-transformer';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { CustomTableService } from 'src/app/project/modules/site/site-table/custom-table/custom-table.service';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { EIconPath } from '../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-point-fields-time',
  templateUrl: './point-fields-time.component.html',
  styleUrls: ['./point-fields-time.component.scss'],
})
export class PointFieldsTimeComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppFieldId: string;
  @Input() ppFieldLabel: string;
  @Input() ppField: TPointCustomField;
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;

  private readonly destroy$ = new Subject<void>();

  @ViewChild('timeCF', { static: false }) timeCustomFieldElement: ElementRef;

  value: string;
  showHoursOnly = false;
  newValue: number;

  displayDays = null;
  displayHours = null;
  displayMinutes = null;

  backupTime = null;

  updating = false;
  success = false;
  error = false;
  public unit = '';
  EIconPath = EIconPath;
  readonly clearObservable$ = new Subject<void>();

  private clickOutsideHandler: ClickOutsideHandler;
  private cancelUpdateField$ = new Subject<void>();
  private updateFieldTimerMs = 500;
  private successTimerMs = 2500;

  constructor(
    private store: Store<{ points: TPoint[] }>,
    private promptService: PromptService,
    private pointFieldsService: PointFieldsService,
    private pointActivityService: PointActivityService,
    private sitePointFilterService: SitePointFilterService,
    private pointsUpdateService: PointsUpdateService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private customTableService: CustomTableService,
  ) {}

  ngAfterViewInit() {
    this.clickOutsideHandler = new ClickOutsideHandler(
      this.timeCustomFieldElement.nativeElement,
      this.destroy$,
    );
    this.clickOutsideHandler.caught$.subscribe((event) => {
      this.onClickOutside(event);
    });
  }

  ngOnChanges() {
    if (this.ppField) {
      const customFields = GET_CUSTOM_FIELDS();

      const customField = customFields[this.ppField.customFieldTemplateId];

      this.value = this.ppField.value;
      this.showHoursOnly = customField?.showHoursOnly;

      this.displayValue();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  clearCustomField(): void {
    this.value = null;
    this.updateField();
  }

  setNewValue(value: number): void {
    this.newValue = value;
  }

  updateField(): void {
    this.clickOutsideHandler.disable();

    if (!this.newValue) {
      return;
    }

    const _id = this.ppPointId;
    const fieldId = this.ppFieldId;
    const workspaceId = this.ppWorkspaceId;

    const valueToSend = this.newValue;
    const newValue = +this.value + +valueToSend;

    this.backupTime = this.value;
    this.value = newValue.toString();

    this.displayValue();
    this.cancelUpdateField$.next();
    this.newValue = null;

    timer(this.updateFieldTimerMs)
      .pipe(
        takeUntil(this.cancelUpdateField$),
        tap(() => {
          this.updateFieldDeferred(_id, fieldId, workspaceId, newValue, valueToSend);
          this.clearObservable$.next();
        }),
      )
      .subscribe();
  }

  displayValue(): void {
    [this.displayMinutes, this.displayHours, this.displayDays] = transformSplitTimeCF(this.value);
  }

  private updateFieldDeferred(
    _id: string,
    fieldId: string,
    workspaceId: string,
    newValue: number,
    valueToSend: number,
  ): void {
    if (this.ppNew) {
      this.updateFieldForNewPoint(_id, fieldId, workspaceId, newValue);

      return;
    }

    this.updating = true;

    const customField: Record<string, string>[] = this.pointFieldsService.createCustomField2(
      fieldId,
      valueToSend.toString(),
      ECustomFieldType.TIME,
    );

    this.pointsUpdateService
      .updatePointField(_id, { customFieldsMap: customField })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const promptText = this.translationPipe.transform('prompt_point_time_update');

          this.customTableService.updatePoint({
            _id: _id,
            field: this.ppFieldLabel,
            newValue: newValue,
            updatedDate: response.header.updatedEpochMillis,
          });

          this.store.dispatch(
            new UpdatePointCustomField({
              workspaceId: workspaceId,
              _id: _id,
              customFieldId: fieldId,
              customFieldValue: newValue,
            }),
          );

          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(workspaceId, _id);
          this.sitePointFilterService.filterPoints(true);

          this.success = true;

          timer(this.successTimerMs).subscribe(() => {
            this.success = false;
          });
        }),
        catchError((error) => {
          this.pointFieldsService.showUpdatePointFieldError(error);

          this.store.dispatch(
            new UpdatePointCustomField({
              workspaceId: workspaceId,
              _id: _id,
              customFieldId: fieldId,
              customFieldValue: this.backupTime,
            }),
          );

          this.value = this.backupTime;

          this.displayValue();

          this.error = true;

          return of();
        }),
        finalize(() => {
          this.updating = false;
        }),
      )
      .subscribe();
  }

  private onClickOutside(event: MouseEvent): void {
    if (this.newValue > 0) {
      this.clickOutsideHandler.disable();
      event.preventDefault();
      event.stopImmediatePropagation();

      const modalData: TConfirmModalParams = {
        message:
          this.translationPipe.transform('close_cf_without_saving_confirm') +
          '<br/>' +
          this.translationPipe.transform('close_cf_without_saving_confirm_time'),
        heading: this.translationPipe.transform('confirm'),
        redButton: true,
        confirmText: this.translationPipe.transform('close_without_saving'),
      };

      this.modalService.setData(modalData);

      this.modalService.showModal(ConfirmModalComponent, {
        blur: false,
        closeWarning: true,
        onClose: (succeeded, closeTransitionPromise) => {
          if (!succeeded) {
            closeTransitionPromise.finally(() => {
              this.clickOutsideHandler.enable();
            });
          }
        },
        callback: () => {
          this.newValue = 0;
        },
      });
    }
  }

  onModelChange(): void {
    this.clickOutsideHandler.enable();
  }

  private updateFieldForNewPoint(
    pointId: string,
    fieldId: string,
    workspaceId: string,
    newValue: number,
  ): void {
    this.store.dispatch(
      new UpdatePointCustomField({
        workspaceId: workspaceId,
        _id: pointId,
        customFieldId: fieldId,
        customFieldValue: newValue,
      }),
    );
  }
}
