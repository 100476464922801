import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { UpdatePointCustomField } from 'src/app/project/modules/points/points.actions';

import { TPoint } from 'src/app/project/modules/points/points.model';

import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';
import { PromptService } from '../../../../../components/prompt/prompt.service';
import { PointActivityService } from '../../point-timeline/point-activity.service';
import { PointFieldsService } from '../point-fields.service';

import { DOCUMENT } from '@angular/common';
import { ClickOutsideHandler } from '@core/services';
import { of, Subject, timer } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { checkClearShortcut } from 'src/app/project/modules/custom-fields/utils/check-clear-shortcut';
import { CustomTableService } from 'src/app/project/modules/site/site-table/custom-table/custom-table.service';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { TAutoNumericOptions } from '../../../../../../core/helpers/create-autonumeric';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { getDecimalPlacesPlaceholder } from '../../../../custom-fields/custom-field-numbers/utils/getDecimalPlacesPlaceholder';
import { getDecimalPlacesValue } from '../../../../custom-fields/custom-field-numbers/utils/getDecimalPlacesValue';

@Component({
  selector: 'pp-point-fields-numbers',
  templateUrl: './point-fields-numbers.component.html',
  styleUrls: ['./point-fields-numbers.component.scss', '../point-fields.component.scss'],
})
export class PointFieldsNumbersComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppFieldId: string;
  @Input() ppFieldLabel: string;
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;
  @Input() ppField: TPointCustomField;

  private readonly destroy$ = new Subject<void>();

  @ViewChild('numbersInput', { static: false }) numbersInputElement: ElementRef;
  @ViewChild('customFieldElement', { static: false }) customFieldElement: ElementRef;

  updating = false;
  success = false;
  error = false;
  unit = '';
  value: string;
  decimalPlaces = 2;
  focused = false;
  showCommas: boolean;
  placeholder = '---.--';
  EIconPath = EIconPath;

  autonumericOptions: TAutoNumericOptions;

  private cancelUpdateField$ = new Subject<void>();
  private clickOutsideHandler: ClickOutsideHandler;
  private updateFieldTimerMs = 500;
  private successTimerMs = 2500;
  private removeClickListenerTimerMs = 100;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{ points: TPoint[] }>,
    private promptService: PromptService,
    private pointFieldsService: PointFieldsService,
    private pointActivityService: PointActivityService,
    private sitePointFilterService: SitePointFilterService,
    private pointsUpdateService: PointsUpdateService,
    private customFieldsService: CustomFieldsService,
    private translationPipe: TranslationPipe,
    private ngZone: NgZone,
    private customTableService: CustomTableService,
  ) {}

  ngOnInit() {
    this.setAutonumericOptions();
  }

  ngAfterViewInit() {
    this.clickOutsideHandler = new ClickOutsideHandler(
      this.customFieldElement.nativeElement,
      this.destroy$,
    );
    this.clickOutsideHandler.caught$.subscribe((event) => {
      this.onClickOutside(event);
    });
  }

  ngOnChanges() {
    const customField =
      this.customFieldsService.getCustomFields()[this.ppWorkspaceId]?.[this.ppFieldId];

    if (customField) {
      this.unit = customField.unit;
      this.showCommas = customField.showCommas;
      this.decimalPlaces = getDecimalPlacesValue(customField.decimalPlaces);

      if (this.decimalPlaces !== 2) {
        this.placeholder = getDecimalPlacesPlaceholder(this.decimalPlaces);
      }

      this.setAutonumericOptions();
    }

    if (this.document.activeElement !== this.numbersInputElement?.nativeElement) {
      this.value = this.ppField?.value;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  clearCustomField(): void {
    this.value = null;

    this.updateField();
  }

  updateField(): void {
    const _id = this.ppPointId;
    const fieldId = this.ppFieldId;
    const fieldValue = this.value;
    const workspaceId = this.ppWorkspaceId;

    this.cancelUpdateField$.next();

    timer(this.updateFieldTimerMs)
      .pipe(
        takeUntil(this.cancelUpdateField$),
        tap(() => {
          this.updateFieldDeferred(_id, fieldId, fieldValue, workspaceId);
        }),
      )
      .subscribe();
  }

  onBlur(): void {
    this.focused = false;

    this.ngZone.runOutsideAngular(() => {
      timer(this.removeClickListenerTimerMs).subscribe(() => {
        this.clickOutsideHandler.disable();
      });
    });
  }

  onFocus(): void {
    this.focused = true;

    this.clickOutsideHandler.enable();
  }

  blurInput(event: Event): void {
    const target = event.target as HTMLElement;

    target.blur();
  }

  onKeyDown(event: KeyboardEvent): void {
    checkClearShortcut(event, () => this.clearCustomField());
  }

  private updateFieldDeferred(
    _id: string,
    fieldId: string,
    fieldValue: string,
    workspaceId: string,
  ): void {
    if (this.ppNew) {
      this.updateFieldForNewPoint(_id, fieldId, fieldValue, workspaceId);

      return;
    }

    this.updating = true;

    const customField: Record<string, string>[] = this.pointFieldsService.createCustomField2(
      fieldId,
      fieldValue,
      ECustomFieldType.NUMBERS,
    );

    this.numbersInputElement.nativeElement.blur();

    this.pointsUpdateService
      .updatePointField(_id, { customFieldsMap: customField })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const promptText = this.translationPipe.transform('prompt_point_numbers_update');

          this.customTableService.updatePoint({
            _id: _id,
            field: this.ppFieldLabel,
            newValue: fieldValue
              ? fieldValue.replace(/,/g, '').replace(/%/g, '').trim()
              : fieldValue,
            updatedDate: response.header.updatedEpochMillis,
          });

          this.store.dispatch(
            new UpdatePointCustomField({
              workspaceId: workspaceId,
              _id: _id,
              customFieldId: fieldId,
              customFieldValue: fieldValue
                ? fieldValue.replace(/,/g, '').replace(/%/g, '').trim()
                : fieldValue,
            }),
          );

          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(workspaceId, _id);
          this.sitePointFilterService.filterPoints(true);

          this.success = true;

          timer(this.successTimerMs).subscribe(() => {
            this.success = false;
          });
        }),
        catchError((error) => {
          this.pointFieldsService.showUpdatePointFieldError(error);

          this.error = true;

          return of();
        }),
        finalize(() => {
          this.updating = false;
        }),
      )
      .subscribe();
  }

  private onClickOutside(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.numbersInputElement.nativeElement.blur();
  }

  private updateFieldForNewPoint(
    pointId: string,
    fieldId: string,
    fieldValue: string,
    workspaceId: string,
  ): void {
    this.store.dispatch(
      new UpdatePointCustomField({
        workspaceId: workspaceId,
        _id: pointId,
        customFieldId: fieldId,
        customFieldValue: fieldValue
          ? fieldValue.replace(/,/g, '').replace(/%/g, '').trim()
          : fieldValue,
      }),
    );
  }

  private setAutonumericOptions(): void {
    this.autonumericOptions = {
      watchExternalChanges: true,
      modifyValueOnWheel: false,
      decimalPlaces: this.decimalPlaces,
      decimalPlacesRawValue: this.decimalPlaces,
      digitGroupSeparator: this.showCommas ? ',' : '',
    };
  }
}
